// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

// These behaviours also affect the inline editable profile on the user's page

on('change', '.js-profile-editable-pronouns-select', function () {
  const select = document.querySelector<HTMLSelectElement>('.js-profile-editable-pronouns-select')!
  const input = document.querySelector<HTMLInputElement>('.js-profile-editable-pronouns-custom')!
  const initialValue = input.getAttribute('data-initial-value')!

  if (select.value === 'Custom') {
    // Reset the input value to the user's pronouns if they're custom
    if ([...select.options].find(option => option.value === initialValue)) {
      input.value = ''
    } else {
      input.value = initialValue
    }

    input.hidden = false
    input.focus()
  } else {
    input.value = select.value
    input.hidden = true
  }
})

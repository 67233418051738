import '../settings/profile'

// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

import {remoteForm} from '@github/remote-form'
import type {SocialAccountEditorElement} from './edit-social-account'

function toggleForm(showForm: boolean) {
  document.querySelector<HTMLElement>('.js-profile-editable-area')!.hidden = showForm
  document.querySelector<HTMLElement>('.js-profile-editable-names')!.hidden = showForm
  document.querySelector<HTMLElement>('.js-profile-editable-form')!.hidden = !showForm
  document.querySelector<HTMLElement>('.js-profile-editable-error')!.textContent = ''

  if (!showForm) {
    // If we erase the pronoun, reset the UI (so it shows correctly on a re-edit)
    const select = document.querySelector<HTMLSelectElement>('.js-profile-editable-pronouns-select')
    const input = document.querySelector<HTMLInputElement>('.js-profile-editable-pronouns-custom')
    if (select && input && input.value === '') {
      select.selectedIndex = 0
      input.hidden = true
    }

    // Reset social account editor fields to their default values and reset their icons to their original state
    for (const editor of document.querySelectorAll<SocialAccountEditorElement>('social-account-editor')) {
      editor.resetToDefault()
    }
    document.querySelector<HTMLElement>('.js-profile-editable-edit-button')!.focus()
  } else {
    document.querySelector<HTMLElement>('.js-profile-editable-form input:not([type="hidden"])')!.focus()
  }
}

function replaceExistingListItems(html: DocumentFragment) {
  const editableArea = document.querySelector<HTMLElement>('.js-profile-editable-replace')!
  editableArea.replaceWith(html)
}

function syncBioInput() {
  const bioContent = document.querySelector<HTMLElement>('.js-user-profile-bio')!.getAttribute('data-bio-text')
  if (typeof bioContent !== 'string') return

  const bioTextField = document.querySelector<HTMLTextAreaElement>('.js-user-profile-bio-edit')!
  bioTextField.value = bioContent
}

on('click', '.js-profile-editable-edit-button', function () {
  syncBioInput()
  toggleForm(true)
})

on('click', '.js-profile-editable-cancel', function () {
  toggleForm(false)
})

remoteForm('.js-profile-editable-form', async (form, wants) => {
  let response

  try {
    response = await wants.html()
  } catch (error) {
    // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
    if (error.response.status === 422) {
      const errorDiv = document.querySelector<HTMLElement>('.js-profile-editable-error')!
      // @ts-expect-error catch blocks are bound to `unknown` so we need to validate the type before using it
      errorDiv.textContent = error.response.json.message
    }

    return
  }

  // Replace the list and hide the form
  replaceExistingListItems(response.html)
  toggleForm(false)
})

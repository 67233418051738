import {asyncCalculateVisibility} from '../behaviors/responsive-underlinenav'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

type TabCountsResponse = {
  data: {
    repositories?: {totalCount: number}
    projects?: {totalCount: number}
    packages?: {totalCount: number}
    teams?: {totalCount: number}
    members?: {totalCount: number}
    discussions?: {totalCount: number}
  }
}

function formatCount(count: number): string {
  if (count > 999) {
    return `${(count / 1000).toFixed(1)}k`
  }

  return count.toLocaleString()
}

function setProfileTabCount(el: HTMLElement, count: number) {
  if (count > 0) {
    el.textContent = formatCount(count)
    el.hidden = false
  } else {
    el.remove()
  }
}

async function loadTabCounts(container: HTMLElement) {
  const url = new URL(container.getAttribute('data-url')!, window.location.origin)
  const params = new URLSearchParams(url.search.slice(1))
  const repoCountEl = container.querySelector<HTMLElement>('.js-profile-repository-count')
  const projectCountEl = container.querySelector<HTMLElement>('.js-profile-project-count')
  const teamCountEl = container.querySelector<HTMLElement>('.js-profile-team-count')
  const memberCountEl = container.querySelector<HTMLElement>('.js-profile-member-count')

  if (repoCountEl) {
    params.append('repo', '1')
  }
  if (projectCountEl) {
    params.append('project', '1')
  }
  if (teamCountEl) {
    params.append('team', '1')
  }
  if (memberCountEl) {
    params.append('member', '1')
  }

  url.search = params.toString()

  const response = await fetch(url.toString(), {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (!response.ok) {
    return
  }
  const result: TabCountsResponse = await response.json()
  const counts = result.data

  if (repoCountEl && counts.repositories) {
    setProfileTabCount(repoCountEl, counts.repositories.totalCount)
  }
  if (projectCountEl && counts.projects) {
    setProfileTabCount(projectCountEl, counts.projects.totalCount)
  }
  if (teamCountEl && counts.teams) {
    setProfileTabCount(teamCountEl, counts.teams.totalCount)
  }
  if (memberCountEl && counts.members) {
    setProfileTabCount(memberCountEl, counts.members.totalCount)
  }

  if (container.classList.contains('js-responsive-underlinenav')) {
    asyncCalculateVisibility(container)
  }
}

observe('.js-profile-tab-count-container', function (container) {
  loadTabCounts(container as HTMLElement)
})
